<template>
  <div class="events">
    <div>
      <!-- 左侧events开始 -->
      <div class="events-news">
        <div class="bordershadow">
          <!-- 类目标题开始 -->
          <div class="titleRow">
            <div class="title">{{ $t("Latest_Events") }}</div>
            <div class="selectStyle">
              <el-select
                v-model="selectValue"
                placeholder="All"
                v-if="years.length"
              >
                <el-option
                  v-for="(item, index) of years"
                  :key="index"
                  :label="$t(item.year.trim().replace(/ /g, '_'))"
                  :value="item.year"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- 类目标题结束 -->
          <!-- 左侧events新闻内容开始 -->
          <ul class="newsUlContainer" v-if="latestMeetings.length">
            <li v-for="(item, index) of latestMeetings" :key="index">
              <div class="newsItem">
                <div>
                  <a @click="handleOpen(item.fromlink)" target="_blank">
                    <p class="p1rowline20">
                      {{ item.title }}
                    </p>
                    <div class="desc2row">
                      {{ item.description }}
                    </div>

                    <div class="date-place">
                      <span>{{ item.beginDate | formatDateTime }}</span>
                      <span>&nbsp;~&nbsp;</span>
                      <span>{{ item.endDate | formatDateTime }}</span>
                      <span class="addr">
                        {{ item.address }}
                      </span>
                    </div>
                  </a>
                </div>
                <div class="newsItem-img" v-show="item.thumb">
                  <a :href="item.fromlink" target="_blank">
                    <img :src="item.thumb" alt="" />
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <!-- 左侧events新闻内容结束 -->
        </div>
        <!-- 分页开始 current(当前页)-->
        <!--current-change,currentPage改变时会触发 current-page当前页数  page-size每页显示个数  page-count总页数  hide-on-single-page只有一页时是否隐藏-->
        <div v-if="page" class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page.pageNum"
            background
            layout="prev, pager, next"
            :page-count="page.totalPage"
            v-show="page.totalPage > 1"
          >
          </el-pagination>
        </div>
        <!-- 分页结束 -->
      </div>
      <!-- 左侧events结束 -->

      <!-- 右侧Latest News开始 -->
      <div class="latest-news bordershadow" v-if="latestNews.length">
        <!-- 类目标题开始 -->
        <div class="titleRow">
          <div class="title">{{ $t("Latest_News") }}</div>
          <div class="more">
            <router-link to="/News">
              {{ $t("More") }}
            </router-link>
          </div>
        </div>
        <!-- 类目标题结束 -->
        <ul class="newsUlContainer">
          <li v-for="(item, index) of latestNews" :key="index">
            <a
              :href="`https://www.ikcest.org/articleS-${item.newsId}.htm`"
              target="_blank"
            >
              <div class="newsItemNoImg">
                <p class="p2row">
                  {{ item.title }}
                </p>
                <p class="dateTime">{{ item.date | formatDateTime }}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- f2右侧Latest News结束 -->
    </div>
        <el-dialog
      v-if="dialogVisible"
      title="提示"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <ALink :aUrl="linkUrl" @close="handleClose"></ALink>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
    ALink: () => import("@/components/ALink"),
  },
  data() {
    return {
      dialogVisible: false,
      linkUrl: "22222",
      selectValue: "All",
      latestMeetings: [], //Latest Events
      latestNews: [],
      page: null,
      years: [],
    };
  },

  mounted() {
    this.getData();
  },
  watch: {
    selectValue() {
      this.getYear();
    },
  },

  methods: {
    handleOpen(value) {
      this.dialogVisible = true;
      this.linkUrl = value;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getData() {
      let url = "/data/api/c2cmeeting.do?action=list&name=meeting&alias=list";
      this.axios.get(url).then((res) => {
        // console.log(res);
        let {
          latestMeetings,
          latestNews,
          page,
          years,
        } = res.data.messages.data.meeting;
        this.latestMeetings = latestMeetings;
        this.latestNews = latestNews.slice(0, 5);
        years.unshift({ year: "All" });
        this.years = years;
        // pageNum初始为0，需要加1
        page.pageNum = page.pageNum + 1;
        this.page = page;
        // console.log(this.page);
      });
    },

    handleCurrentChange(newPage) {
      this.page.pageNum = newPage;
      this.getPagination();
      // console.log(newPage)
    },

    getYear() {
      let selectYear = this.selectValue;
      if (this.selectValue == "All") {
        selectYear = "";
      }
      let url =
        "/data/api/c2cmeeting.do?action=meeting&name=meeting&year=" +
        selectYear;
      this.axios.get(url).then((res) => {
        this.latestMeetings = res.data.messages.data.meetings.contents;
        let Yearpage = res.data.messages.data.meetings.page;
        Yearpage.pageNum = Yearpage.pageNum + 1;
        this.page = Yearpage;
      });
    },

    getPagination() {
      let selectYear = this.selectValue;
      if (this.selectValue == "All") {
        selectYear = "";
      }
      let url =
        "/data/api/c2cmeeting.do?action=meeting&name=meeting&year=" +
        selectYear +
        "&pageSize=10&pageNum=" +
        this.page.pageNum +
        "&alias=list";
      this.axios.get(url).then((res) => {
        this.latestMeetings = res.data.messages.data.meetings.contents;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
.el-dialog{
  height: 430px !important;
}
.events {
  padding-top: 110px;
  width: 100%;
  min-width: 1200px;
  background-color: #f7f8f8;
  color: #444546;
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

// 左侧events开始
.events-news {
  width: 725px;
  margin-right: 15px;
  margin-bottom: 30px;
  // border: 1px solid red;
}

.selectStyle {
  width: 100px;
  margin-top: -2px;
  ::v-deep .el-input__inner {
    height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
}

.date-place {
  padding: 10px 0px;
  span {
    color: #98999a;
    font-size: 12px;
    font-weight: 400;
  }
  a {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
  }
  .addr {
    color: #009fe3;
  }
}

//2行文字标题,行高20
.p1rowline20 {
  width: 100%;
  height: 20px;
  padding-right: 5px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}

// 描述两行
.desc2row {
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  color: #666768;
  line-height: 21px;
  padding-right: 5px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

.latest-news {
  width: 460px;
  height: 584px;
}

//可共用样式 驼峰
// 新闻内容
.newsUlContainer {
  li + li {
    border-top: 1px solid #dde0e1;
  }
}

.newsItem {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  .newsItem-img img {
    width: 120px;
    height: 88px;
  }
}

.newsItemNoImg {
  padding: 10px 0px;
}

// 标题行
.titleRow {
  height: 30px;
  line-height: 30px;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  .title {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      line-height: 30px;
      border-radius: 50%;
      border: 3px solid #009fe3;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
  .more {
    cursor: pointer;
    a {
      color: #009fe3;
    }
  }
}

// 日期样式
.dateTime {
  font-size: 12px;
  font-weight: 400;
  color: #98999a;
  padding: 10px 0px;
}

// 2行文字
.p2row {
  width: 100%;
  height: 51px;
  padding-right: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

//2行文字标题,行高20
.p2rowline20 {
  width: 100%;
  height: 42px;
  padding-right: 5px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

// 分页
.pagination {
  margin-top: 20px;
  ::v-deep .el-pagination.is-background .btn-prev {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .btn-next {
    background-color: white;
  }
  // 修改页数默认背景颜色
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff; //修改选中项背景
  }
}

//1行描述
.desc {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #666768;
  line-height: 18px;
  padding-right: 5px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}
</style>
